import { TooltipProps, Tooltip } from '@mui/material';
import React from 'react';

export type VibTooltipProps = TooltipProps;

export const VibTooltip = (props: VibTooltipProps) => {
  return (
    <Tooltip
      slotProps={{
        tooltip: { className: 'bg-primary/90' },
      }}
      enterTouchDelay={0}
      leaveTouchDelay={3000}
      {...props}
    >
      {props.children}
    </Tooltip>
  );
};
