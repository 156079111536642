import {
  Dispatch,
  MouseEventHandler,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Drawer,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useRouter } from 'next/router';

export interface VibSidebarMenuItem {
  label: string;
  path?: string;
  icon?: ReactNode;
  open?: boolean;
  onClick?: MouseEventHandler;
  hidden?: boolean;
  subItems?: VibSidebarMenuItem[];
  testId?: string;
}

interface VibSidebarProps {
  menuItems: VibSidebarMenuItem[];
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function VibSidebar({
  menuItems,
  open,
  setOpen,
}: VibSidebarProps) {
  const router = useRouter();
  const [itemExpanded, setItemExpanded] = useState('');
  const [itemActivated, setItemActivated] = useState('');

  const urlPath = router.pathname;

  function setupMenuItems(menuItems: VibSidebarMenuItem[]) {
    menuItems.forEach((item) => {
      if (item.subItems?.length && urlPath?.startsWith(item?.path || '')) {
        setItemExpanded(item.label);
      }
      if (urlPath === item.path) {
        setItemActivated(item.label);
      }
      if (item.subItems?.length) {
        setupMenuItems(item.subItems);
      }
    });
  }

  useEffect(() => {
    setupMenuItems(menuItems);
  }, [urlPath]);

  function showExpandIcon(item: VibSidebarMenuItem) {
    if (!item.subItems?.length) {
      return;
    }
    return itemExpanded === item.label ? (
      <ArrowDropUpIcon className="text-white" />
    ) : (
      <ArrowDropDownIcon className="text-white" />
    );
  }

  function mountMenu(
    menuItems: VibSidebarMenuItem[],
    isSubMenu = false,
    subMenuDepth = 0,
  ) {
    let subMenuPaddingLeft = 4;
    if (subMenuDepth > 1) {
      subMenuPaddingLeft += subMenuDepth;
    }
    return (
      <List>
        {menuItems.map((item: VibSidebarMenuItem) => {
          if (item.hidden) return null;

          return (
            <ListItem
              key={item.label}
              disablePadding
              className={`block ${
                open && item.label === itemActivated ? 'bg-black/[0.1]' : ''
              }`}
            >
              <ListItemButton
                sx={{
                  opacity: isSubMenu && !open ? 0 : 1,
                  pl: isSubMenu ? subMenuPaddingLeft : '',
                }}
                onClick={(evt) => {
                  if (item.subItems?.length) {
                    if (!open) {
                      setItemExpanded(item.label);
                      setOpen(true);
                    } else {
                      setItemExpanded(
                        itemExpanded !== item.label ? item.label : '',
                      );
                    }
                  } else if (item.onClick) {
                    setItemActivated(item.label);
                    item.onClick(evt);
                    window.screen.width <= 1024 && setOpen(false);
                  }
                }}
                data-testid={item.testId}
              >
                <ListItemIcon className="min-w-[48px] sm:pl-1">
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{ className: 'text-white text-sm' }}
                />
                {showExpandIcon(item)}
              </ListItemButton>
              {item.subItems?.length && (
                <Collapse
                  in={itemExpanded === item.label}
                  className={`${!open && 'hidden'}`}
                >
                  {mountMenu(item.subItems, true, subMenuDepth + 1)}
                </Collapse>
              )}
            </ListItem>
          );
        })}
      </List>
    );
  }
  return (
    <Drawer
      variant={window.screen.width <= 1024 ? 'temporary' : 'persistent'}
      open={open}
      // Propriedade deprecada, porém a sintaxe com slotProps gera o erro "Warning: React does not recognize the `slotProps` prop on a DOM element."
      BackdropProps={{
        classes: { root: 'mt-navbarHeight' },
        onClick: () => setOpen(!open),
      }}
      ModalProps={{ className: 'mt-navbarHeight' }}
      PaperProps={{
        className:
          'fixed mt-navbarHeight h-screen bg-primary shrink-0 z-sidebar overflow-x-hidden w-sidebarOpenWidth',
      }}
    >
      {mountMenu(menuItems)}
    </Drawer>
  );
}
