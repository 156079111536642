import NextImage, {
  ImageProps as NextImageProps,
  StaticImageData,
} from 'next/image';
import { twMerge } from 'tailwind-merge';

export type ImageProps = NextImageProps;

export const Image = (props: ImageProps) => {
  const imageStyles = '';

  const imageSrc = (props.src || {}) as StaticImageData;
  const placeholder =
    imageSrc.blurDataURL || props.blurDataURL ? 'blur' : 'empty';

  return (
    <NextImage
      placeholder={placeholder}
      {...props}
      className={twMerge(imageStyles, props.className)}
    />
  );
};
