import { AlertColor } from '@mui/material';
import VibToast, { SnackbarContent } from '@vibTheme/components/VibToast';
import { createContext, PropsWithChildren, useState } from 'react';

interface ToastContextValue {
  setContent: (message: string, type?: AlertColor) => void;
}

export const ToastContext = createContext({} as ToastContextValue);

export function ToastContextProvider({ children }: PropsWithChildren) {
  const [open, setOpen] = useState(false);
  const [snackbar, setSnackbar] = useState<SnackbarContent>({
    type: 'success',
    message: '',
  });

  function setContent(message: string, type?: AlertColor) {
    setSnackbar({ message, type });
    setOpen(true);
  }

  return (
    <ToastContext.Provider value={{ setContent }}>
      {children}
      <VibToast content={snackbar} open={open} setOpen={setOpen} />
    </ToastContext.Provider>
  );
}
