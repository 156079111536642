import { PropsWithChildren } from 'react';
import vibLogo from '@public/vibx_logo.png';
import { AppBar } from '@mui/material';
import { Link } from '@vibTheme/components/_base/Link';
import { Image } from '@vibTheme/components/_base/Image';

export default function LayoutAppbarOnly({ children }: PropsWithChildren) {
  return (
    <div className="flex flex-col min-h-screen">
      <AppBar className="fixed justify-center h-navbarHeight px-4">
        <Link href="/" className="flex items-center w-fit">
          <Image src={vibLogo} alt="Vibx Logo" height={50} />
        </Link>
      </AppBar>

      <main
        className={`flex flex-col items-center justify-center flex-1 p-main mt-navbarHeight`}
      >
        {children}
      </main>
    </div>
  );
}
