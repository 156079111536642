export function validateCNPJ(cnpj: string): boolean {
  // Remove any non-digit characters from the CNPJ
  const cleanedCnpj = cnpj.replace(/\D/g, '');

  // Check if the CNPJ has a valid length
  if (cleanedCnpj.length !== 14) {
    return false;
  }

  // Check for repeated digits
  if (/^(\d)\1+$/.test(cleanedCnpj)) {
    return false;
  }

  // Validate the verification digits
  const verifierDigits = cleanedCnpj.slice(12);
  const partialCnpj = cleanedCnpj.slice(0, 12);

  function calculateDigit(digits: string, factor: number): number {
    let sum = 0;

    for (let i = 0; i < digits.length; i++) {
      sum += parseInt(digits[i], 10) * factor--;
      if (factor < 2) {
        factor = 9;
      }
    }

    const remainder = sum % 11;
    return remainder < 2 ? 0 : 11 - remainder;
  }

  const firstDigit = calculateDigit(partialCnpj, 5);
  const secondDigit = calculateDigit(partialCnpj + firstDigit, 6);

  return verifierDigits === `${firstDigit}${secondDigit}`;
}
