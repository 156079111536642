import { isToggleActive } from '@api/featureToggle';
import { isToggleActiveDto } from '@interfaces/featureToggle';
import { useEffect, useState } from 'react';

export const useFeatureToggleAccess = (props: isToggleActiveDto) => {
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false);
  const [isFeatureLoading, setIsFeatureLoading] = useState(true);

  const fetchFeatureToggle = async () => {
    try {
      const { success } = await isToggleActive(props);
      setIsFeatureEnabled(success);
    } catch (err) {
      console.error(`Erro ao buscar featureToggle ${props.name}:`);
      console.error(err);
    } finally {
      setIsFeatureLoading(false);
    }
  };

  useEffect(() => {
    fetchFeatureToggle();
  }, []);

  return { isFeatureEnabled, isFeatureLoading };
};
