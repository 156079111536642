import { Groups } from '@interfaces/auth';
import { CreateUserDto, PlatformUser } from '@interfaces/profile';
import { ResponseBoolean } from '@interfaces/api';
import { fetcher } from '@utils/fetcher';

const API_URL = '/api/users';

export const createUser = async (dto: CreateUserDto): Promise<PlatformUser> =>
  fetcher(`${API_URL}/create`, {
    method: 'POST',
    body: dto,
  });

export const getGroups = async (): Promise<Groups> =>
  fetcher(`${API_URL}/groups`);

export const isVibUser = async (dto: PlatformUser): Promise<ResponseBoolean> =>
  fetcher(`${API_URL}/isVibUser/${dto.document}`);
