import { fetcher } from '@utils/fetcher';
import {
  PlatformUser,
  ProfileExistsByDto,
  ProfileExistsByResponse,
  ResetPasswordResponse,
  ToggleAutomaticWithdralEnabledDto,
  UpdateUserDto,
  UserBalance,
  Validate2faCodeDto,
  ValidatePixKeyCredentials,
  ValidatePixKeyResponse,
  _2faStatus,
} from '@interfaces/profile';
import { ResetPasswordInput, UpdatePasswordDto } from '@interfaces/auth';
import { ResponseBoolean } from '@interfaces/api';

const API_URL = '/api/profile';

export const getBalance = async (): Promise<UserBalance> =>
  fetcher(`${API_URL}/balance`);

export const profileExistsBy = async (
  dto: ProfileExistsByDto,
): Promise<ProfileExistsByResponse> =>
  fetcher(`${API_URL}/existsBy`, {
    method: 'POST',
    body: dto,
  });

export const getPersonalData = async (): Promise<PlatformUser> =>
  fetcher(`${API_URL}/personalData`);

export const resetPassword = async (
  dto: ResetPasswordInput,
): Promise<ResetPasswordResponse> =>
  fetcher(`${API_URL}/resetPassword`, {
    method: 'POST',
    body: dto,
  });

export const toggleAutomaticWithdral = async (
  dto: ToggleAutomaticWithdralEnabledDto,
): Promise<ResponseBoolean> =>
  fetcher(`${API_URL}/toggleAutomaticWithdral`, {
    method: 'PATCH',
    body: dto,
  });

export const updatePassword = async (
  dto: UpdatePasswordDto,
): Promise<ResponseBoolean> =>
  fetcher(`${API_URL}/updatePassword`, {
    method: 'POST',
    body: dto,
  });

export const validatePixKey = async (
  dto: ValidatePixKeyCredentials,
): Promise<ValidatePixKeyResponse> =>
  fetcher(`${API_URL}/validatePixKey`, {
    method: 'POST',
    body: dto,
  });

export const updateUser = async (dto: UpdateUserDto) =>
  fetcher(`${API_URL}/update`, {
    method: 'PATCH',
    body: dto,
  });

export const get2faStatus = async (): Promise<_2faStatus> =>
  fetcher(`${API_URL}/2fa/status`);

export const generate2faCode = async (): Promise<void> =>
  fetcher(`${API_URL}/2fa/generateCode`);

export const validate2faCode = async (dto: Validate2faCodeDto): Promise<void> =>
  fetcher(`${API_URL}/2fa/validateCode`, { method: 'PATCH', body: dto });
