export const saveRefToLocalStorage = (refValue: any) => {
  localStorage.setItem('referrerCode', refValue);
};

export const removeRefFromLocalStorage = () => {
  localStorage.removeItem('referrerCode');
};

export const getRefFromLocalStorage = () => {
  return localStorage.getItem('referrerCode');
};
