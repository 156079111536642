import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ReportsIcon from '@mui/icons-material/TableChart';
import SubscriptionsIcon from '@mui/icons-material/RecentActors';
import ReceiptForecastsIcon from '@mui/icons-material/Toll';
import InvoicesIcon from '@mui/icons-material/MonetizationOn';
import RefundsIcon from '@mui/icons-material/CurrencyExchange';
import CartAbandonmentsIcon from '@mui/icons-material/ProductionQuantityLimits';
import PixIcon from '@mui/icons-material/Pix';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import UpdatePasswordIcon from '@mui/icons-material/Key';
import {
  Avatar,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  AppBar,
} from '@mui/material';
import { useRouter } from 'next/router';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import VibSidebar, {
  VibSidebarMenuItem,
} from '@vibTheme/components/VibSidebar';
import { useSession, signOut } from 'next-auth/react';
import { WelcomeDialog } from '@components/WelcomeDialog';
import { RankingDisplay } from '@components/Ranking/RankingDisplay';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ScheduleMessages from '@mui/icons-material/ForwardToInbox';
import WithdralIcon from '@mui/icons-material/Savings';
import AddLinkIcon from '@mui/icons-material/AddLink';
import HelpIcon from '@mui/icons-material/Help';
import BotContactsIcon from '@mui/icons-material/ContactPage';
import AffiliationIcon from '@mui/icons-material/Loyalty';
import AffiliationMarketIcon from '@mui/icons-material/Storefront';
import MyAffiliationsIcon from '@mui/icons-material/Diversity1';
import AffiliationRequestsIcon from '@mui/icons-material/Beenhere';
import { useFeatureToggleAccess } from '@hooks/useFeatureToggleAccess';
import ReferrerCommissionsIcon from '@mui/icons-material/RequestQuote';
import { VIBX_SUPPORT_LINK } from '@utils/constants';
import MyTelegramIcon from '@mui/icons-material/Telegram';
import TgMyLoginsIcon from '@mui/icons-material/Face3';
import TgWelcomeMessagesIcon from '@mui/icons-material/Upcoming';

export default function Layout({ children }: PropsWithChildren) {
  const router = useRouter();
  const { data } = useSession();
  const { user: authenticatedUser } = data || {};
  const platformUserId = Number(authenticatedUser?.id);
  const { isFeatureEnabled: isWithdrawalRequestEnabled } =
    useFeatureToggleAccess({
      name: 'withdrawalRequest',
      valuesToValidate: { platformUserId },
    });
  const { isFeatureEnabled: isAffiliationEnabled } = useFeatureToggleAccess({
    name: 'affiliation',
    valuesToValidate: { platformUserId },
  });
  const { isFeatureEnabled: isAffiliationMarketEnabled } =
    useFeatureToggleAccess({
      name: 'affiliationMarket',
      valuesToValidate: { platformUserId },
    });
  const { isFeatureEnabled: isReferVibEnabled } = useFeatureToggleAccess({
    name: 'referVib',
    valuesToValidate: { platformUserId },
  });
  const { isFeatureEnabled: isLoginTelegramEnabled } = useFeatureToggleAccess({
    name: 'loginTelegram',
    valuesToValidate: { platformUserId },
  });

  const menuItemReferVib = useMemo(
    () => [
      isReferVibEnabled && (
        <MenuItem key="refer-vib" onClick={() => router.push('/referVib')}>
          <ListItemIcon>
            <AddLinkIcon fontSize="small" />
          </ListItemIcon>
          Indique e ganhe
        </MenuItem>
      ),
    ],
    [isReferVibEnabled],
  );

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const showProfileMenu = Boolean(anchorEl);

  const handleClick = (evt: any) => {
    setAnchorEl(evt.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    signOut();
  };

  const menuItems: VibSidebarMenuItem[] = useMemo(
    () => [
      {
        label: 'Dashboard',
        icon: <DashboardIcon className="text-white" />,
        path: '/',
        onClick: () => {
          router.push('/');
        },
      },
      {
        label: 'Produtos',
        icon: <ShoppingBagIcon className="text-white" />,
        path: '/products',
        onClick: () => {
          router.push('/products');
        },
        testId: 'menu-products',
      },
      {
        label: 'Relatórios',
        icon: <ReportsIcon className="text-white" />,
        path: '/reports',
        subItems: [
          {
            label: 'Assinaturas',
            icon: <SubscriptionsIcon className="text-white" />,
            path: '/reports/subscriptions',
            onClick: () => {
              router.push('/reports/subscriptions');
            },
          },
          {
            label: 'Vendas',
            icon: <InvoicesIcon className="text-white" />,
            path: '/reports/sales',
            onClick: () => {
              router.push('/reports/sales');
            },
          },
          {
            label: 'Vendas de Afiliados',
            icon: <InvoicesIcon className="text-white" />,
            path: '/reports/affiliatedSales',
            onClick: () => {
              router.push('/reports/affiliatedSales');
            },
          },
          {
            label: 'Vendas como Afiliado',
            icon: <InvoicesIcon className="text-white" />,
            path: '/reports/salesAsAffiliate',
            onClick: () => {
              router.push('/reports/salesAsAffiliate');
            },
          },
          {
            label: 'Previsão de Recebimentos',
            icon: <ReceiptForecastsIcon className="text-white" />,
            path: '/reports/receiptForecasts',
            onClick: () => {
              router.push('/reports/receiptForecasts');
            },
          },
          {
            label: 'Histórico de Recebimentos',
            icon: <PixIcon className="text-white" />,
            path: '/reports/receiptHistory',
            onClick: () => {
              router.push('/reports/receiptHistory');
            },
          },
          {
            label: 'Reembolsos',
            icon: <RefundsIcon className="text-white" />,
            path: '/reports/refunds',
            onClick: () => {
              router.push('/reports/refunds');
            },
          },
          {
            label: 'Carrinhos Abandonados',
            icon: <CartAbandonmentsIcon className="text-white" />,
            path: '/reports/cartAbandonments',
            onClick: () => {
              router.push('/reports/cartAbandonments');
            },
          },
          {
            label: 'Contatos do Bot',
            icon: <BotContactsIcon className="text-white" />,
            path: '/reports/botContacts',
            onClick: () => {
              router.push('/reports/botContacts');
            },
          },
          {
            label: 'Comissões por indicação',
            icon: <ReferrerCommissionsIcon className="text-white" />,
            path: '/reports/referrerCommissions',
            onClick: () => {
              router.push('/reports/referrerCommissions');
            },
          },
        ],
      },
      {
        label: 'Gestão de Reembolsos',
        icon: <RefundsIcon className="text-white" />,
        path: '/pendingRefund',
        onClick: () => {
          router.push('/pendingRefund');
        },
      },
      {
        label: 'Envio de mensagens em massa',
        icon: <ScheduleMessages className="text-white" />,
        path: '/scheduleMessages',
        onClick: () => {
          router.push('/scheduleMessages');
        },
      },
      {
        label: 'Saque',
        icon: <WithdralIcon className="text-white" />,
        path: '/withdral',
        hidden: !isWithdrawalRequestEnabled,
        onClick: () => {
          router.push('/withdral');
        },
      },
      {
        label: 'Afiliações',
        hidden: !isAffiliationEnabled,
        icon: <AffiliationIcon className="text-white" />,
        path: '/affiliation',
        subItems: [
          {
            label: 'Minhas Afiliações',
            icon: <MyAffiliationsIcon className="text-white" />,
            path: '/affiliation/myAffiliations',
            onClick: () => {
              router.push('/affiliation/myAffiliations');
            },
          },
          {
            label: 'Mercado de Afiliações',
            hidden: !isAffiliationEnabled || !isAffiliationMarketEnabled,
            icon: <AffiliationMarketIcon className="text-white" />,
            path: '/affiliation/affiliationMarket',
            onClick: () => {
              router.push('/affiliation/affiliationMarket');
            },
          },
          {
            label: 'Pedidos de Afiliação',
            icon: <AffiliationRequestsIcon className="text-white" />,
            path: '/affiliation/affiliationRequests',
            onClick: () => {
              router.push('/affiliation/affiliationRequests');
            },
          },
        ],
      },
      {
        label: 'Meu Telegram',
        icon: <MyTelegramIcon className="text-white" />,
        path: '/myTelegram',
        hidden: !isLoginTelegramEnabled,
        subItems: [
          {
            label: 'Meus Logins',
            icon: <TgMyLoginsIcon className="text-white" />,
            path: '/myTelegram/myLogins',
            onClick: () => {
              router.push('/myTelegram/myLogins');
            },
          },
          {
            label: 'Mensagens de boas-vindas',
            icon: <TgWelcomeMessagesIcon className="text-white" />,
            path: '/myTelegram/welcomeMessages',
            onClick: () => {
              router.push('/myTelegram/welcomeMessages');
            },
          },
        ],
      },
      {
        label: 'Ajuda',
        icon: <HelpIcon className="text-white" />,
        path: '/help',
        onClick: () => {
          router.push('/help');
        },
      },
    ],
    [
      isWithdrawalRequestEnabled,
      isAffiliationEnabled,
      isAffiliationMarketEnabled,
      isLoginTelegramEnabled,
    ],
  );

  useEffect(() => {
    window.screen.width >= 1024 && setSidebarOpen(true);
  }, []);

  return (
    <>
      <WelcomeDialog />

      <AppBar className="fixed z-appbar h-navbarHeight">
        <Toolbar className="flex justify-between bg-white text-black px-main">
          <IconButton
            onClick={() => setSidebarOpen((sidebarOpen) => !sidebarOpen)}
            className="p-0"
          >
            {sidebarOpen ? (
              <MenuOpenIcon className="text-primary text-[1.7rem]" />
            ) : (
              <MenuIcon className="text-primary text-[1.7rem]" />
            )}
          </IconButton>
          <div className="flex grow justify-end">
            <RankingDisplay />
            <Tooltip title={authenticatedUser?.name}>
              <IconButton
                onClick={handleClick}
                size="small"
                className="p-0"
                aria-controls={showProfileMenu ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={showProfileMenu ? 'true' : undefined}
              >
                <Avatar className="w-[40px] h-[40px] bg-primary" />
              </IconButton>
            </Tooltip>
          </div>
          <Menu
            anchorEl={anchorEl}
            open={showProfileMenu}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            disableScrollLock
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
          >
            <MenuItem onClick={() => router.push('/profile/personalData')}>
              <ListItemIcon>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              Perfil
            </MenuItem>

            <MenuItem onClick={() => router.push(VIBX_SUPPORT_LINK)}>
              <ListItemIcon>
                <HelpIcon fontSize="small" />
              </ListItemIcon>
              Ajuda
            </MenuItem>

            <MenuItem onClick={() => router.push('/profile/updatePassword')}>
              <ListItemIcon>
                <UpdatePasswordIcon fontSize="small" />
              </ListItemIcon>
              Alterar senha
            </MenuItem>
            {menuItemReferVib}
            <MenuItem onClick={handleSignOut}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <main
        className={`min-h-[calc(100vh-theme(space.navbarHeight))] transition-[margin-left] z-main ease-in-out flex mt-navbarHeight ${
          sidebarOpen && 'lg:ml-sidebarOpenWidth'
        }`}
      >
        <VibSidebar
          menuItems={menuItems}
          open={sidebarOpen}
          setOpen={setSidebarOpen}
        />

        <section className="w-full overflow-hidden p-main bg-white">
          {children}
        </section>
      </main>
    </>
  );
}
