import { Alert, AlertColor, Snackbar } from '@mui/material';

export interface SnackbarContent {
  type?: AlertColor;
  message: string;
}

export interface VibToastProps {
  content: SnackbarContent;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function VibToast({ content, open, setOpen }: VibToastProps) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert severity={content?.type || 'success'} variant="filled">
        {content.message}
      </Alert>
    </Snackbar>
  );
}
