import { PhoneNumberUtil } from 'google-libphonenumber';
import { z } from 'zod';

const phoneUtil = PhoneNumberUtil.getInstance();
const isPhoneValid = (phone: any) => {
  if (typeof phone !== 'string') return false;

  try {
    const number = phoneUtil.parseAndKeepRawInput(phone);
    return phoneUtil.isValidNumber(number);
  } catch {
    return false;
  }
};

export const validatePhone = (args?: ValidatePhoneArgs) => {
  const validator = z.string();

  if (args?.isOptional)
    return validator
      .optional()
      .transform((val) => (isPhoneValid(val || '') ? val : null));

  return validator.refine(isPhoneValid, 'O formato do telefone é inválido.');
};

type ValidatePhoneArgs = {
  isOptional?: boolean;
};
