export function validateCPF(cpf: string): boolean {
  const cleanedCPF = cpf.replace(/\D/g, '');

  if (cleanedCPF.length !== 11 || /^(\d)\1{10}$/.test(cleanedCPF)) {
    return false;
  }

  const cpfDigits = Array.from(cleanedCPF, Number);
  const firstDigit = calculateVerificationDigit(cpfDigits.slice(0, 9));
  const secondDigit = calculateVerificationDigit(
    cpfDigits.slice(0, 9).concat(firstDigit),
  );

  return cpfDigits[9] === firstDigit && cpfDigits[10] === secondDigit;
}

function calculateVerificationDigit(digits: number[]): number {
  let sum = 0;

  for (let i = 0; i < digits.length; i++) {
    sum += digits[i] * (digits.length + 1 - i);
  }

  const remainder = sum % 11;
  return remainder < 2 ? 0 : 11 - remainder;
}
