import useGroupsContext from '@contexts/GroupsContext/hook';
import useToastContext from '@contexts/ToastContext/hook';
import { FindBestByProductsResponse } from '@interfaces/ranking';
import { useEffect, useState } from 'react';
import TrophyIcon from '@mui/icons-material/EmojiEvents';
import { VibTooltip } from '@vibTheme/components/VibTooltip';
import { Typography } from '@vibTheme/components/_base/Typography';
import { Link } from '@vibTheme/components/_base/Link';
import { findBestByProducts } from '@api/ranking';

export const RankingDisplay = () => {
  const [rankings, setRankings] = useState<FindBestByProductsResponse>([]);
  const [position, setPosition] = useState(0);
  const [shallRevalidate, setShallRevalidate] = useState(false);
  const { getAllProductsIds } = useGroupsContext();
  const botConfigIds = getAllProductsIds();
  const toast = useToastContext();
  const currentProduct = rankings[position];

  const fetchRankings = async () => {
    try {
      const data = await findBestByProducts({ botConfigIds });
      setRankings(data);
    } catch {
      toast.setContent(
        `Ranking não disponível! Tente novamente mais tarde.`,
        'error',
      );
    }
  };

  useEffect(() => {
    fetchRankings();
  }, [shallRevalidate]);

  useEffect(() => {
    const revalidateCycle = setInterval(
      () => setShallRevalidate((value) => !value),
      900000,
    );
    const swapRankingDisplay = setInterval(() => {
      rankings.length &&
        setPosition((prevPosition) => (prevPosition + 1) % rankings.length);
    }, 60000);
    return () => {
      clearInterval(revalidateCycle);
      clearInterval(swapRankingDisplay);
    };
  }, []);

  const resolveTrophyStyles = () => {
    switch (currentProduct?.position) {
      case 1:
        return 'text-gold';

      case 2:
        return 'text-silver';

      case 3:
        return 'text-bronze';

      default:
        return 'hidden';
    }
  };
  const trophyStyles = resolveTrophyStyles();

  if (!rankings.length) return null;

  return (
    <VibTooltip
      title={
        <Typography className="text-white">
          Seu produto <strong>{currentProduct.productName}</strong> está em{' '}
          {currentProduct.position}º lugar no nosso ranking de vendas:
          <br />
          <Link
            href="https://marketplace.vibx.com.br/"
            className="text-white font-bold"
          >
            https://marketplace.vibx.com.br/
          </Link>
        </Typography>
      }
    >
      <div className="rounded-full bg-opacity-20 bg-gray-300 flex items-center w-[15rem] md:w-auto p-sm mx-auto md:mr-md">
        <div className="flex max-w-full font-semibold gap-sm">
          <TrophyIcon className={trophyStyles} />
          <p className="truncate">
            {currentProduct.position}º - {currentProduct.productName}
          </p>
        </div>
      </div>
    </VibTooltip>
  );
};
