import useToastContext from '@contexts/ToastContext/hook';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react';
import { _2faStatus } from '@interfaces/profile';
import { useFeatureToggleAccess } from '@hooks/useFeatureToggleAccess';
import { get2faStatus } from '@api/profile';
import { VibLoadingOverlay } from '@vibTheme/components/VibLoadingOverlay';

export const _2FA_BASE_URL = '/profile/2fa';

type User2faContextValue = {
  status: _2faStatus | null;
  setStatus: Dispatch<SetStateAction<User2faContextValue['status']>>;
  shouldRefetch: boolean;
  setShouldRefetch: Dispatch<SetStateAction<boolean>>;
  isUserConfirmed: boolean;
};

export const User2faContext = createContext<User2faContextValue>({
  status: null,
  setStatus: () => null,
  isUserConfirmed: false,
  shouldRefetch: false,
  setShouldRefetch: () => false,
});

type Props = PropsWithChildren;

export const User2faContextProvider = (props: Props) => {
  const { data } = useSession();
  const router = useRouter();
  const toast = useToastContext();

  const [status, setStatus] = useState<User2faContextValue['status']>(null);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [isUserConfirmed, setIsUserConfirmed] = useState(false);

  const is2faPage = router.pathname.startsWith(_2FA_BASE_URL);
  const _2faUrl = `${_2FA_BASE_URL}?callbackUrl=${router.pathname}`;
  const callbackUrl = router.query.callbackUrl;
  const redirectToCallbackUrl = () => router.push(String(callbackUrl));

  const {
    isFeatureEnabled: isGlobal2faEnabled,
    isFeatureLoading: isGlobal2faLoading,
  } = useFeatureToggleAccess({
    name: 'login2fa',
    valuesToValidate: { platformUserId: Number(data?.user?.id) },
  });

  const fetch2faStatus = async () => {
    try {
      const status = await get2faStatus();
      const isUserConfirmed = status.emailConfirmed && status.phoneConfirmed;
      isGlobal2faEnabled && setIsUserConfirmed(isUserConfirmed);
      setStatus(status);
    } catch {
      toast.setContent('Ocorreu um erro ao buscar o status da verificação 2FA');
    } finally {
      setShouldRefetch(false);
    }
  };

  useEffect(() => {
    if (isGlobal2faLoading) return;

    isGlobal2faEnabled ? fetch2faStatus() : setIsUserConfirmed(true);
  }, [isGlobal2faEnabled, isGlobal2faLoading]);

  useEffect(() => {
    isGlobal2faEnabled &&
      status &&
      !isUserConfirmed &&
      !is2faPage &&
      router.push(_2faUrl);

    status?.emailConfirmed &&
      status.phoneConfirmed &&
      is2faPage &&
      redirectToCallbackUrl();
  }, [router.pathname, status]);

  // Aciona uma hidratação do status quando algum passo do fluxo 2fa for concluído
  useEffect(() => {
    shouldRefetch && fetch2faStatus();
  }, [shouldRefetch]);

  // Redireciona para callbackUrl quando o fluxo 2fa for concluído por completo
  useEffect(() => {
    isUserConfirmed && callbackUrl && redirectToCallbackUrl();
  }, [isUserConfirmed]);

  if (!isUserConfirmed && !is2faPage) return <VibLoadingOverlay />;

  return (
    <User2faContext.Provider
      value={{
        status,
        setStatus,
        shouldRefetch,
        setShouldRefetch,
        isUserConfirmed,
      }}
    >
      {props.children}
    </User2faContext.Provider>
  );
};
