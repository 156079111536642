import useGroupsContext from '@contexts/GroupsContext/hook';
import { UserProduct } from '@interfaces/auth';
import { useRouter } from 'next/router';
import { PropsWithChildren, createContext, useEffect, useState } from 'react';

type CurrentProductContextValue = {
  currentProduct: UserProduct | null;
};

export const CurrentProductContext = createContext(
  {} as CurrentProductContextValue,
);

export const CurrentProductContextProvider = (props: PropsWithChildren) => {
  const [currentProduct, setCurrentProduct] = useState<UserProduct | null>(
    null,
  );

  const router = useRouter();
  const code = router.query.code as string;

  const { getAllProducts } = useGroupsContext();

  const getCurrentProduct = async () => {
    const userProduct = getAllProducts().find(
      (product) => product.productCode === code,
    );

    userProduct ? setCurrentProduct(userProduct) : router.push('/404');
  };

  useEffect(() => {
    code && currentProduct?.productCode !== code && getCurrentProduct();
  }, [code]);

  return (
    <CurrentProductContext.Provider value={{ currentProduct }}>
      {props.children}
    </CurrentProductContext.Provider>
  );
};
