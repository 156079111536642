import { UserRole, UserRoles } from '@interfaces/auth';

// Para entender o formato das strings de permissão, ver seção "Banco de Dados" da documentação
// https://docs.google.com/document/d/1UF2py20dng-Z9kme6WAweW5HqClkuRusjzSZ7BSEMHE
export const parseFeaturePermission = (
  permissionString: string | undefined,
): UserRole[] => {
  if (permissionString === undefined) return [UserRoles.OWNER];

  if (!permissionString.match(/[0|1]{3}/g))
    throw new Error('O formato da string de permissão é inválido.');

  const userRoles: UserRole[] = [];

  if (permissionString.charAt(0) === '1') userRoles.push(UserRoles.OWNER);
  if (permissionString.charAt(1) === '1') userRoles.push(UserRoles.COPRODUCER);
  if (permissionString.charAt(2) === '1') userRoles.push(UserRoles.AFFILIATED);

  return userRoles;
};
