import { createTheme } from '@mui/material/styles';
import { poppins } from './fonts';
import { ptBR } from '@mui/x-data-grid';

const vibTheme = createTheme(
  {
    palette: {
      primary: {
        main: '#712AD9',
      },
    },
    typography: {
      allVariants: {
        color: '#000000',
      },
      button: {
        textTransform: 'none',
      },
      fontFamily: [
        poppins.style.fontFamily,
        'Roboto',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  },
  ptBR,
);

export default vibTheme;
