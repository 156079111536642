import {
  FindBestByProducts,
  FindBestByProductsResponse,
} from '@interfaces/ranking';
import { fetcher } from '@utils/fetcher';

export const findBestByProducts = async (
  dto: FindBestByProducts,
): Promise<FindBestByProductsResponse> =>
  fetcher('/api/ranking/findBestByProducts', {
    method: 'POST',
    body: dto,
  });
