import { findFeaturePermissions } from '@api/featurePermission';
import useGroupsContext from '@contexts/GroupsContext/hook';
import useToastContext from '@contexts/ToastContext/hook';
import {
  FeatureName,
  FeaturePermission,
  UserPermissions,
} from '@interfaces/featurePermission';
import { Product } from '@interfaces/product';
import { parseFeaturePermission } from '@utils/featurePermissions';
import { VibLoadingOverlay } from '@vibTheme/components/VibLoadingOverlay';
import { PropsWithChildren, createContext, useEffect, useState } from 'react';

export type FeaturePermissionsContextValue = {
  featurePermissions: FeaturePermission[];
  getUserPermissions: (
    featureName: FeatureName,
    productId: Product['id'],
  ) => UserPermissions;
};

export const FeaturePermissionsContext = createContext(
  {} as FeaturePermissionsContextValue,
);

export const FeaturePermissionsContextProvider = (props: PropsWithChildren) => {
  const [featurePermissions, setFeaturePermissions] = useState<
    FeaturePermissionsContextValue['featurePermissions']
  >([]);
  const [isStateReady, setIsStateReady] = useState(false);

  const { getUserRoleByProduct } = useGroupsContext();
  const toast = useToastContext();

  const fetchPermissions = async () => {
    try {
      const data = await findFeaturePermissions();
      setFeaturePermissions(data);
      setIsStateReady(true);
    } catch {
      toast.setContent(
        'Ocorreu um erro ao buscar permissões de funcionalidade. Por favor, entre em contato com o suporte.',
        'error',
      );
    }
  };

  useEffect(() => {
    fetchPermissions();
  }, []);

  const getUserPermissions: FeaturePermissionsContextValue['getUserPermissions'] =
    (featureName, productId) => {
      const featurePermission = featurePermissions.find(
        (fp) => fp.name === featureName,
      );
      const userRole = getUserRoleByProduct(productId);

      const canView = parseFeaturePermission(featurePermission?.view).includes(
        userRole,
      );
      const canCreate = parseFeaturePermission(
        featurePermission?.create,
      ).includes(userRole);
      const canUpdate = parseFeaturePermission(
        featurePermission?.update,
      ).includes(userRole);
      const canRemove = parseFeaturePermission(
        featurePermission?.remove,
      ).includes(userRole);
      const canExport = parseFeaturePermission(
        featurePermission?.export,
      ).includes(userRole);

      return {
        ...(canView !== undefined && { canView }),
        ...(canCreate !== undefined && { canCreate }),
        ...(canUpdate !== undefined && { canUpdate }),
        ...(canRemove !== undefined && { canRemove }),
        ...(canExport !== undefined && { canExport }),
      };
    };

  if (!isStateReady) return <VibLoadingOverlay />;

  return (
    <FeaturePermissionsContext.Provider
      value={{ featurePermissions, getUserPermissions }}
    >
      {props.children}
    </FeaturePermissionsContext.Provider>
  );
};
